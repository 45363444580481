import React, { useState, useEffect } from "react";
import { Global, css } from "@emotion/react";
import InteractiveMap from "@aerisweather/javascript-sdk/dist/maps/interactive/InteractiveMap";
import InteractiveMapApp from "@aerisweather/javascript-sdk/dist/apps/InteractiveMapApp";
import ButtonPanelView from "@aerisweather/javascript-sdk/dist/apps/views/ButtonPanel";
import { Absolute, Box, Theme } from "pws-design-system/design-system";
import aeris from "../../../api/";
import PageLayout from "../../common/layouts/PageLayout";
import Map, {
  stationMarkerConfigurator,
  obsFields
} from "../../common/components/map/MapController";
import UnitToggle from "../../common/components/unit-toggle/";
import { matchesMediaQuery } from "../../common/components/responsive-render/ResponsiveRender";
import Place from "../../../models/aeris/place";
import Station from "../../../models/aeris/place/Station";
import Observation from "../../../models/aeris/observation";
import MapPanel from "../../common/components/map/MapPanel";
import useUser from "../../common/hooks/useUser";
import { UnitStoreContainer } from "../../common/hooks/stores/useUnitStore";
import { TimeStoreContainer } from "../../common/hooks/stores/useTimeStore";
import _isNil from "lodash.isnil";
import _get from "lodash.get";
import _startCase from "lodash.startcase";
import { buildStationPath } from "../../../models/path";
import { useMessages } from "../../common/hooks/useMessages";
import PlaceMeta from "../../../models/aeris/place/PlaceMeta";
import { toBounds } from "@aerisweather/javascript-sdk/dist/utils/strings";
import Response from "../../../models/aeris/response";
import { ClockType } from "../../../types/enums";
import { ThemeContainer } from "../../common/hooks/useTheme";
import { Providers } from "../../common/layouts/Layout";

const sleep = (delay, callback) => setTimeout(callback, delay);

export enum MapEvent {
  ZOOM = "zoom",
  CHANGE_BOUNDS = "change.bounds",
  READY = "ready"
}

async function fetchStation(placeId: string, fields: string[]): Promise<Response> {
  const request = aeris
    .api()
    .endpoint("observations")
    // .fields(observation.buildFields(fields))
    .place(placeId)
    .filter("metar;pws;madis;ausbom,allownosky,precise")
    .limit(1);
  const response = await request.get();
  return new Response(response);
}

async function fetchStationOb(placeId: string, fields: string[] = null): Promise<any> {
  let obsData: any;
  // if placeId is a place name, fetch nearby METAR stations to determine if there's one close
  // enough to use as the official station for the place, otherwise fall back to "allstations"
  const response = await fetchStation(placeId, fields);
  if (response.data) {
    obsData = response.data;
  }
  if (Array.isArray(obsData) && obsData.length > 0) {
    obsData = obsData[0];
  }

  return obsData;
}

const dashboardUrl = (ob: Observation): string => {
  if (ob.place) {
    const type = ob.place.placeType === "location" ? "local" : "station";
    return `/${type}/${ob.place.url}`;
  }
  return buildStationPath("dashboard", ob.id);
};

type MapPageProps = any & {
  path: string;
  initialStationType?: string;
  place?: Place;
};

const MapPageContent = ({
  path,
  handleAutosuggestResultClick,
  initialStationType = "metar;pws;madis;ausbom",
  place
}: MapPageProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const api = aeris.api();
  api._paramKeys.push("mindist");

  const user = useUser();
  const [appRef, setAppRef] = useState();
  const [activePlace, setActivePlace] = useState();
  const [placeObservation, setPlaceObservation] = useState();
  const [myStationObservation, setMyStationObservation] = useState();
  const [activeObservation, setActiveObservation] = useState();
  const [selectedMarker, setSelectedMarker] = useState();
  const [markerSource, setMarkerSource] = useState();
  const [layerAction, setLayerAction] = useState();
  const { units, unitType } = UnitStoreContainer.useContainer();
  const { timeFormat } = TimeStoreContainer.useContainer();
  const [markerParam, setMarkerParam] = useState("temps");

  async function fetchPlaceOb(place: Place) {
    let data = await fetchStationOb(place.placeId);
    // if we have a valid place but no observation yet, create a dummy obs dataset from the place info
    // to prevent display errors for the station on the map
    if (_isNil(data) || Object.keys(data).length === 0) {
      data = {
        id: place.displayId,
        loc: place.getData("loc")
      };
    }
    setPlaceObservation(new Observation({ data }));
  }
  const setupMapApp = (app: InteractiveMapApp, timeFormat: ClockType, obsParam: string) => {
    const base = 5120;
    const map = app.map;
    const timelinePanel = app.getPanel("timeline");
    if (timelinePanel) {
      timelinePanel.setFormatters({
        time: timeFormat === ClockType.TwelveHour ? "h:mm a" : "H:mm",
        day: null
      });
    }
    app.addSource("station-obs", "vector", {
      requiresBounds: true,
      data: {
        url: (params: any): string => {
          const request = aeris
            .api()
            .endpoint("observations")
            .action("within")
            .param("mindist", `${base / Math.pow(2, map.getZoom())}km`)
            .place(toBounds(app.map.strategy.getBounds()))
            .filter("allownosky,precise")
            .limit(1000)
            .sort("dt:-1")
            .fields(obsFields.join(","));

          if (!params.filter) {
            request.filter("metar;pws;madis;ausbom,allownosky,precise");
            //request.filter("metar,allownosky,precise");
          }
          if (params && params.filter) {
            let filter = params.filter;
            if (filter === "all") filter = "metar;pws;madis;ausbom";
            filter += ",allownosky,precise";
            request.filter(filter);
          }
          return request.url();
        },
        properties: {
          root: "response"
        }
      },
      style: {
        marker: (data: any): string => {
          return stationMarkerConfigurator(data, map, markerParam);
        }
      }
    });
  };
  const selectMarker = (markerData: any, map: InteractiveMap) => {
    setSelectedMarker((prev: any) => {
      if (prev && prev.marker) {
        map.strategy.updateMarker(prev.marker, {
          style: stationMarkerConfigurator(prev.data, map, markerParam, false)
        });
      }
      return markerData;
    });
  };

  const clearSelectedMarker = () => {
    setActiveObservation(null);
    setMarkerSource(null);
  };

  useEffect(() => {
    if (!appRef || !user || !user.stations || user.stations.length === 0) {
      return;
    }

    const app = appRef;
    const { stations } = user;

    // app.map.addSource(source, "my-stations");
    const layersPanel: ButtonPanelView = app.panels.layers;
    const segments = stations.map(station => {
      const { stationId } = station;
      return {
        id: stationId,
        value: stationId,
        title: stationId
      };
    });

    // inject place layer control at top of layers panel
    layersPanel.insertAt(
      0,
      {
        id: "my-stations",
        title: "My Stations",
        value: "my-stations",
        filter: true,
        multiselect: false,
        segments
      },
      true
    );
  }, [user, appRef]);

  // fetch place obs when active place changes
  useEffect(() => {
    if (activePlace) {
      fetchPlaceOb(activePlace);
    } else {
    }
  }, [activePlace]);

  const removeMarkersForSource = layerId => {
    // clear all my station markers from the map
    // deleting the source causes the first filter to be selected whichis not what we want
    const strategy = appRef.map.strategy;
    let source = strategy.sources.filter(source => source.identifier === layerId);

    if (source.length) {
      source = source[0];
      const markers = source.markers;
      markers.forEach(marker => {
        strategy.removeMarker(marker);
      });
    }
  };

  // update place source when place obs data changes
  useEffect(() => {
    const app = appRef;
    if (!app) return;
    // remove existing place-obs source if exists
    // const existing = app.map.getSourceForId("my-stations");
    // if (existing) {
    //   app.map.removeSource(existing);
    // }
    if (myStationObservation) {
      const source = app.addSource("my-stations", "vector", {
        requiresBounds: false,
        data: {
          items: [myStationObservation.data]
        },
        style: {
          marker: (data: any): string => {
            data.place = activePlace;
            // if associated place is a Place and not Station, update its id to be the shortened
            // place name instead of the obs station id for displaying
            if (activePlace && activePlace.placeType !== "station") {
              data.id = activePlace.formattedPlaceShort;
            }
            const placeLabel = path && path.type === "local" ? place.formattedPlaceShort : null;
            return stationMarkerConfigurator(
              data,
              app.map,
              markerParam,
              false,
              placeLabel,
              "primary"
            );
          }
        }
      });
      app.map.addSource(source, "my-stations");
      setMarkerSource("my-stations");
      setActiveObservation(myStationObservation);
    }
  }, [myStationObservation]);

  // update place source when place obs data changes
  useEffect(() => {
    const app = appRef;
    if (!app) return;

    const myStationsButton = _get(app, "panels.layers.buttons", []).filter(
      button => _get(button, "id", null) === "my-stations"
    );

    if (myStationsButton.length && _get(myStationsButton[0], "state.selected", false) === true) {
      return;
    }

    // remove existing place-obs source if exists
    // const existing = app.map.getSourceForId("place-obs");
    // if (existing) {
    //   app.map.removeSource(existing);
    // }
    removeMarkersForSource("place-obs");
    if (placeObservation) {
      const source = app.addSource("place-obs", "vector", {
        requiresBounds: false,
        data: {
          items: [placeObservation.data]
        },
        style: {
          marker: (data: any): string => {
            data.place = activePlace;
            // if associated place is a Place and not Station, update its id to be the shortened
            // place name instead of the obs station id for displaying
            if (activePlace && activePlace.placeType !== "station") {
              data.id = activePlace.formattedPlaceShort;
            }
            const placeLabel = path && path.type === "local" ? place.formattedPlaceShort : null;
            return stationMarkerConfigurator(
              data,
              app.map,
              markerParam,
              false,
              placeLabel,
              "primary"
            );
          }
        }
      });
      app.map.addSource(source, "place-obs");
      //commented out for the addition of lat/lon zoom in url
      // app.map.setView({ lat: placeObservation.lat, lon: placeObservation.lon });
      setMarkerSource("place-obs");
      setActiveObservation(placeObservation);
    }
  }, [placeObservation]);

  async function fetchMyStationOb(id) {
    let data = await fetchStationOb(`pws_${id}`);
    const station = user.stations.filter(station => station.stationId === id)[0];

    if (_isNil(data) || Object.keys(data).length === 0) {
      data = {
        id: id,
        loc: { lat: station.location.lat, lon: station.location.long }
      };
    }
    setMyStationObservation(new Observation({ data }));
    // setActiveObservation(new Observation({ data }));
    appRef.map.setView({ lat: station.location.lat, lon: station.location.long });
  }

  // handle layer actions when selected/deselected
  // this is a hack since React's state values were inaccessible via layer select/deselect event
  // handlers on the app instance directly for some reason :/
  useEffect(() => {
    const app = appRef;
    if (layerAction && app) {
      const { action, id: layerId, filter } = layerAction;
      if (action === "select") {
        if (layerId === "place-obs") {
          appRef.panels.layers.deselect("my-stations");
          if (activePlace) {
            if (activePlace.hasAll(["lat", "lon"])) {
              const zoom = activePlace.placeType === "location" ? 10 : 12;
              app.map.setView({ lat: activePlace.lat, lon: activePlace.lon }, zoom);
            }
            // re-fetch station obs so we always have the latest data
            fetchPlaceOb(activePlace);
          }
        } else if (layerId === "my-stations") {
        }
      } else if (action === "deselect") {
        if (layerId === markerSource) {
          removeMarkersForSource(layerId);
          clearSelectedMarker();
        }
      } else if (action === "layer:change:option") {
        if (layerId === "my-stations" && filter) {
          removeMarkersForSource("my-stations");
          const myStationsButton = _get(app, "panels.layers.buttons", []).filter(
            button => _get(button, "id", null) === "my-stations"
          );
          if (
            myStationsButton.length &&
            _get(myStationsButton[0], "state.selected", false) === false
          ) {
            clearSelectedMarker();
          } else if (myStationsButton[0].value.filters !== filter) {
            return;
          } else {
            clearSelectedMarker();
            appRef.panels.layers.deselect("place-obs");
            fetchMyStationOb(filter);
            clearSelectedMarker();
          }
        }
      }
    }
  }, [layerAction]);

  // update selected marker style
  useEffect(() => {
    if (selectedMarker) {
      const marker = selectedMarker.marker;
      if (marker) {
        appRef.map.strategy.updateMarker(marker, {
          style: stationMarkerConfigurator(selectedMarker.data, appRef.map, markerParam, true)
        });
      }
    }
  }, [selectedMarker]);

  // deselect selected marker when active obs is unset
  useEffect(() => {
    if (!activeObservation && selectedMarker) {
      selectMarker(null, appRef.map);
    }
  }, [activeObservation]);

  // update markers on unit toggle
  useEffect(() => {
    const app = appRef;
    if (!app) return;

    let markers: any[] = [];

    const stationsSource = app.map.getSourceForId("station-obs");
    const placeSource = app.map.getSourceForId("place-obs");

    if (stationsSource) {
      markers = markers.concat(stationsSource.markers);
    }

    if (placeSource) {
      markers = markers.concat(placeSource.markers);
    }
    markers
      .filter(marker => _isNil(marker) === false)
      .forEach((marker: any) => {
        const mapEl = marker.renderable;
        if (mapEl) {
          const selected = selectedMarker && selectedMarker.marker === mapEl;
          app.map.strategy.updateMarker(mapEl, {
            style: stationMarkerConfigurator(marker.data, app.map, markerParam, selected)
          });
        }
      });
  }, [units, unitType]);

  useEffect(() => {
    const app = appRef;
    if (!app) return;
    const timelinePanel = app.getPanel("timeline");
    if (timelinePanel) {
      timelinePanel.setFormatters({
        time: timeFormat === ClockType.TwelveHour ? "h:mm a" : "H:mm",
        day: null
      });
    }
  }, [timeFormat]);

  const updateUrlLocation = (center: { [key: string]: any }, zoom: number) => {
    // center["zoom"] = zoom;
    const params = new URLSearchParams(center);
    let url = new URL(window.location.href);
    url.searchParams.set("lat", center["lat"]);
    url.searchParams.set("lon", center["lon"]);
    url.searchParams.set("zoom", zoom);
    // url.searchParams.set('ob',newParam);
    history.pushState({}, "", url.href);
    // window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
  };
  const handleMapReady = (map: InteractiveMap, app: InteractiveMapApp) => {
    setAppRef(app);
    setupMapApp(app, timeFormat, markerParam);

    app
      .on("layer:select", (e: any) => {
        setLayerAction({ action: "select", id: e.data.id });
      })
      .on("layer:deselect", (e: any) => {
        setLayerAction({ action: "deselect", id: e.data.id });
        const id = _get(e, "data.id", null);
      })
      .on("layer:change:option", (e: any) => {
        const id = _get(e, "data.id", null);
        const filter = _get(e, "data.params.filter", null);
        setLayerAction({ action: "layer:change:option", id, filter });
      });

    app.map.on("marker:click", (e: any) => {
      const eventData = e.data || {};
      const data = eventData.data;
      const source = data.awxjs_source;

      if (source === "station-obs" || source === "place-obs") {
        // update selected marker state
        if (eventData.marker) {
          selectMarker(eventData, app.map);
        }
        if (data) {
          const observation = new Observation({ data });
          setActiveObservation(observation);
        }
        setMarkerSource(source);
      } else {
        clearSelectedMarker();
      }
    });

    app.map.on("change:center change:zoom", () =>
      updateUrlLocation(app.map.getCenter(), app.map.getZoom())
    );

    const layersPanel: ButtonPanelView = app.panels.layers;
    if (place) {
      // inject place layer control at top of layers panel
      layersPanel.insertAt(
        0,
        {
          title: "",
          buttons: [
            {
              title:
                place instanceof Station ? place.displayId.toUpperCase() : place.formattedPlace,
              value: "place-obs",
              selected: true
            }
          ]
        },
        true
      );
      setActivePlace(place);
    }
    layersPanel.select("station-obs");

    layersPanel.insertAt(
      0,
      {
        id: "observations",
        title: "Observations",
        segments: [
          {
            title: "Temperatures",
            id: "temps",
            value: "temps"
          },
          {
            title: "Dew Points",
            id: "dewpt",
            value: "dewpt"
          },
          {
            title: "Feels Like",
            id: "feelsLike",
            value: "feelsLike"
          },
          {
            title: "Humidity",
            id: "rh",
            value: "rh"
          },
          {
            title: "Wind Speed",
            id: "winds",
            value: "winds"
          },
          {
            title: "Wind Gusts",
            id: "windGusts",
            value: "windGusts"
          },
          {
            title: "Precip",
            id: "ppt",
            value: "ppt"
          }
          // {
          //   title: "Heat Index",
          //   id: "heat-index",
          //   value: "heat-index,states-outlines-dk"
          // },
          // {
          //   title: "Wind Chill",
          //   id: "wind-chill",
          //   value: "wind-chill,states-outlines-dk"
          // }
        ],
        onChange: value => {
          let newParam = value.value[0];
          setMarkerParam(newParam);

          let markers: any[] = [];

          const stationsSource = app.map.getSourceForId("station-obs");
          const placeSource = app.map.getSourceForId("place-obs");

          if (stationsSource) {
            markers = markers.concat(stationsSource.markers);
          }

          if (placeSource) {
            markers = markers.concat(placeSource.markers);
          }

          //part of the hack around state not updating in stationMarkerConfig from
          //initial setupMap
          //throw ob param in url to reflect current state
          const urlParams = new URLSearchParams(window.location.search);
          let url = new URL(window.location.href);
          url.searchParams.set("ob", newParam);
          history.pushState({}, "", url.href);

          markers
            .filter(marker => _isNil(marker) === false)
            .forEach((marker: any) => {
              const mapEl = marker.renderable;
              if (mapEl) {
                const selected = selectedMarker && selectedMarker.marker === mapEl;
                app.map.strategy.updateMarker(mapEl, {
                  style: stationMarkerConfigurator(marker.data, app.map, newParam, selected)
                });
              }
            });
        }
      },
      true
    );
    const params = new URLSearchParams(window.location.search);
    if (params.has("ob")) {
      const obParam = params.get("ob");
      console.log(obParam);
      layersPanel.select(obParam);
    } else {
      layersPanel.select("temps");
    }
  };

  return (
    <>
      <Global
        styles={css`
          body {
            min-height: 100vh;
          }
          .awxjs__app__component {
            font-family: ${Theme.fonts.body};
          }
          .awxjs__app__ui-panel-timeline-time {
            font-family: ${Theme.fonts.heading};

            .time {
              font-size: ${Theme.fontSizes["2xl"]};
              font-weight: bold;
              line-height: 1em;
              margin-top: 5px;
            }
            .day {
              font-size: ${Theme.fontSizes["sm"]};
              line-height: 1em;
            }
          }
          .awxjs__ui-timeline {
            .awxjs__ui-timeline__periods {
              > .awxjs__ui-timeline__periods-item {
                > span {
                  font-weight: bold;
                }
              }
            }
          }
          .awxjs__ui-tooltip {
            background: rgba(255, 255, 255, 0.95);
          }
          /* .awxjs__app__ui-panel[style] {
            overflow-y: initial !important;
          } */
          .awxjs__legend-radar[style] {
            min-width: 320px !important;
          }
        `}
      />
      <Box position="relative" width="100%" height="100%">
        <Map
          type="InteractiveMapApp"
          place={place}
          mapReadyHandler={handleMapReady}
          config={{
            map: {
              layers: "radar-global",
              zoom: place ? (place.placeType === "location" ? 10 : 12) : undefined
            },
            panels: {
              search: {
                enabled: false
              },
              timeline: {
                range: {
                  range: {
                    min: -12,
                    max: 0
                  }
                }
              },
              layers: {
                position: {
                  translate: matchesMediaQuery("mobile") ? { x: -10, y: 55 } : { x: -10, y: 55 }
                },
                buttons: [
                  {
                    id: "radar-global",
                    value: "radar-global:80",
                    title: "Radar"
                  },
                  {
                    id: "satellite",
                    value: "satellite:75",
                    title: "Satellite"
                  },
                  {
                    id: "alerts",
                    value: "alerts",
                    title: "Alerts"
                  },
                  {
                    id: "station-obs",
                    title: "Station Type",
                    value: "station-obs",
                    filter: true,
                    multiselect: true,
                    segments: [
                      {
                        id: "station-obs-all",
                        value: "all",
                        title: "All"
                      },
                      {
                        id: "station-obs-pws",
                        value: "pws",
                        title: "PWS"
                      },
                      {
                        id: "station-obs-metar",
                        value: "metar",
                        title: "METAR"
                      },
                      {
                        id: "station-obs-mesonet",
                        value: "mesonet",
                        title: "MADIS"
                      }
                    ]
                  }
                ]
              }
            }
          }}
        />
        <Absolute top="10px" right="8px">
          <Box p={1} rounded="full" backgroundColor="rgba(0,0,0,0.8)">
            <UnitToggle context="components.mapPage" {...theme.components.mapPage.toggle} />
          </Box>
        </Absolute>
        {activeObservation && (
          <MapPanel
            observation={activeObservation}
            url={dashboardUrl(activeObservation)}
            handleCloseClick={() => setActiveObservation(null)}
          />
        )}
      </Box>
    </>
  );
};

const MapPageDisplay = (props: MapPageProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const messages = useMessages();
  let metaTitle;
  let metaDescription;

  if (props.context === "station") {
    const placeMeta = new PlaceMeta(props.place, props.path);
    metaTitle = placeMeta.buildMeta("map", "title");
    metaDescription = placeMeta.buildMeta("map", "description");
  } else {
    metaTitle = messages.station_map_page_title;
    metaDescription = messages.station_map_meta_description;
  }

  return (
    <PageLayout
      context="mapPage"
      containerProps={{
        display: "flex",
        flexDirection: "column",
        height: "100vh"
      }}
      headerProps={{
        pt: [3, null, 4],
        pb: [3, null, 4],
        bg: theme.colors.bg.base.primary,
        context: "mapPage"
      }}
      footerProps={{
        variant: "minimal"
      }}
      contentProps={{
        px: 0,
        flex: "1"
      }}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <MapPageContent {...props} />
    </PageLayout>
  );
};

function MapPage(props) {
  return (
    <Providers>
      <MapPageDisplay {...props}>{props.children}</MapPageDisplay>
    </Providers>
  );
}

export default MapPage;
