import Place from "./Place";
import Path from "../../path/Path";
import _isNil from "lodash.isnil";
import { messageStore } from "../../../react/common/hooks/useMessages";
import _template from "lodash.template";

function format(val: any, formatter: (val: string) => string) {
  return _isNil(val) ? "" : formatter(val);
}

export default class PlaceMeta {
  constructor(private place: Place, private path: Path) {}

  get templateMap() {
    return {
      station: {
        dashboard: {
          title: messageStore.dashboard_station_title,
          description: messageStore.dashboard_location_meta_description
        },
        forecast: {
          title: messageStore.forecast_station_title,
          description: messageStore.forecast_station_meta_description
        },
        historical: {
          title: messageStore.historical_station_title,
          description: messageStore.historical_station_meta_description
        },
        map: {
          title: messageStore.map_station_title,
          description: messageStore.map_station_meta_description
        }
      },
      local: {
        dashboard: {
          title: messageStore.dashboard_location_title,
          description: messageStore.dashboard_location_meta_description
        },
        forecast: {
          title: messageStore.forecast_location_title,
          description: messageStore.forecast_location_meta_description
        },
        historical: {
          title: messageStore.historical_location_title,
          description: messageStore.historical_location_meta_description
        },
        map: {
          title: messageStore.map_location_title,
          description: messageStore.map_location_meta_description
        }
      }
    };
  }

  buildMeta(page, type: "title" | "description") {
    const template = _template(this.templateMap[this.path.type][page][type], {
      interpolate: /{([\s\S]+?)}/g
    });
    return template(this.tokens);
  }

  get type() {
    if (this.place.type === "pws") {
      return "Personal Weather Station";
    }
    return `${format(this.place.type, (val: string) => val.toUpperCase())} station`;
  }

  get location() {
    const country = this.place.countryFull;
    if (country === "United States") {
      if (this.place.stateFull) {
        return `${this.place.name}, ${this.place.stateFull}`;
      }
      return `${this.place.name}, ${this.place.countryFull}`;
    } else {
      if (this.place.stateFull) {
        return `${this.place.name}, ${this.place.stateFull}, ${this.place.countryFull}`;
      } else {
        return `${this.place.name},  ${this.place.countryFull}`;
      }
    }
  }

  get tokens() {
    return {
      type: this.type,
      location: this.location,
      id: this.place.id,
      idUpperCase: format(this.place.displayId, (val: string) => val.toUpperCase()),
      pageName: this.path.view
    };
  }
}
